import { groupBy, isArray, isEmpty } from 'lodash';

import {
  DOMUI_CLUB_DISTRICT_DATA,
  SITECORE_INDIVIDUAL_TOKEN,
} from '../constants';

import { addLocalisationToPath } from '@utils/localized-navigate';

export function getSiteCoreIndividualToken() {
  const individual = window.localStorage.getItem(SITECORE_INDIVIDUAL_TOKEN);
  return individual !== 'undefined' && !!individual
    ? JSON.parse(individual)
    : {};
}

export function getClubDistrictFromLocal() {
  const getClubDistrictData = window.localStorage.getItem(
    DOMUI_CLUB_DISTRICT_DATA
  );
  return getClubDistrictData !== 'undefined' && !!getClubDistrictData
    ? JSON.parse(getClubDistrictData)
    : {};
}

// eslint-disable-next-line camelcase
export async function MyRotary_getTokens() {
  let clubName;
  let clubGUID;
  let districtID;
  let districtName;

  const data = getClubDistrictFromLocal();
  if (data.getClubDistrictData) {
    const { districtLabel = '' } = data;
    const { id = '' } = data.getClubDistrictData;

    const { organizations = [] } = data.getClubDistrictData;
    const districts = organizations
      .filter(
        organization =>
          organization.district && organization.district.length > 0
      )
      .flatMap(organization => organization.district);

    // Get Club Info
    if (!isEmpty(organizations) && isArray(organizations)) {
      const { id, name: firstClubName, type } = organizations[0];
      // eslint-disable-next-line no-restricted-globals, no-global-assign
      clubName = `${firstClubName} (${type.replace('_', ' ')})`;
      clubGUID = id;
    }

    // Get District Info
    if (id && !isEmpty(districts) && isArray(districts)) {
      const activeDistrict = organizations.filter(
        organization =>
          organization?.type === 'District' &&
          organization?.activeAssignment === true &&
          organization?.endDate === null
      );
      const activeDistrictIds = activeDistrict.map(d => d.id);

      const allMatchingDistricts = organizations.flatMap(organization =>
        organization?.district.filter(district =>
          activeDistrictIds.includes(district?.id)
        )
      );

      const extractedDistrictData = allMatchingDistricts.map(
        ({ id, riDistrictId, isHomeDistrict, zone }) => ({
          id,
          riDistrictId,
          isHomeDistrict,
          zone,
        })
      );
      const [firstDistrict] = extractedDistrictData;

      const { id, riDistrictId } = firstDistrict || {};
      districtID = id;
      districtName = `${districtLabel} ${riDistrictId}`;
    }

    // Get Individual Profile
    const { firstName, lastName, middleName } = data.individualInfo;
    // const individual = getSiteCoreIndividualToken();

    const logOutUrl = data?.logOutUrl;

    let formattedLogoutURL = null;
    if (logOutUrl) {
      const isHttpURL =
        logOutUrl.indexOf('http://') === 0 ||
        logOutUrl.indexOf('https://') === 0;
      formattedLogoutURL = isHttpURL ? logOutUrl.split('//')[1] : logOutUrl;
    }

    const individualToken = id
      ? {
          'primary-club-guid': clubGUID || '',
          'primary-club-text': clubName || '',
          'primary-district-guid': districtID || '',
          'primary-district-text': districtName || '',
          'member-id': id,
          'user-firstname': firstName || '',
          'user-lastname': lastName || '',
          'user-middlename': middleName || '',
          'logout-url': formattedLogoutURL || '',
        }
      : {};

    return individualToken;
  }
}

// eslint-disable-next-line camelcase
export async function MyRotary_getAllClubs() {
  const data = getClubDistrictFromLocal();
  if (data.getClubDistrictData) {
    const { organizations = [] } = data.getClubDistrictData;
    const { weekDayLabels } = data;

    if (!isEmpty(organizations) && isArray(organizations)) {
      let clubDetails = [];
      if (organizations[0]) {
        const { id, name, type, meetings = [] } = organizations[0];
        if (organizations[0].activeAssignment) {
          const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
          const clubUrl = `${appUrl}${addLocalisationToPath(
            `/domui/club/${id}/details`
          )}`;
          const meetingDetails = meetings[0];

          const clubToken = {
            'club-guid': id,
            'club-text': `${name} (${type?.replace('_', ' ')})`,
            'club-url': clubUrl,
            'district-guid': id,
          };

          if (
            !isEmpty(meetingDetails) &&
            !!meetingDetails.weekday &&
            !!meetingDetails.time
          ) {
            clubToken['next-meeting-details'] = [
              `${weekDayLabels[meetingDetails.weekday]} ${meetingDetails.time}`,
            ];
          }
          clubDetails = [clubToken];
          return clubDetails;
        }
      }
    }
  }
}
// eslint-disable-next-line camelcase
export async function MyRotary_getAllDistrcits() {
  const data = getClubDistrictFromLocal();
  if (data.getClubDistrictData) {
    const { organizations = [] } = data.getClubDistrictData;

    if (organizations?.length > 0) {
      const activeDistrict = organizations.filter(
        organization =>
          organization?.type === 'District' &&
          organization?.activeAssignment === true &&
          organization?.endDate === null
      );
      let districtDetails = [];
      // const districts = clubs[0]?.district;
      const groupedDistricts =
        !isEmpty(organizations) && isArray(organizations)
          ? groupBy(organizations, 'district.id')
          : [];
      const districtLabel = 'District';

      if (!isEmpty(activeDistrict) && isArray(activeDistrict)) {
        const activeDistrictIds = activeDistrict.map(d => d.id);

        const allMatchingDistricts = organizations.flatMap(organization =>
          organization?.district.filter(district =>
            activeDistrictIds.includes(district?.id)
          )
        );

        const extractedDistrictData = allMatchingDistricts.map(
          ({ id, riDistrictId, isHomeDistrict, zone }) => ({
            id,
            riDistrictId,
            isHomeDistrict,
            zone,
          })
        );
        const [firstDistrict] = extractedDistrictData;

        const { id, riDistrictId, isHomeDistrict, zone } = firstDistrict || {};
        // Now you can safely use those fields
        const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
        const districtUrl = `${appUrl}${addLocalisationToPath(
          `/domui/district/${id}/details`
        )}`;
        const clubsByDistrict = !isEmpty(groupedDistricts)
          ? groupedDistricts[id]?.map(club => club.clubId)
          : [];

        const districtToken = {
          'district-guid': id,
          'district-text': `${districtLabel} ${riDistrictId}`,
          'district-url': districtUrl,
          'is-primary': isHomeDistrict,
          'district-number': riDistrictId,
          'zone-number': zone,
          clubs: clubsByDistrict,
        };
        districtDetails = [districtToken];
        return districtDetails;
      }
    } else {
      return [];
    }
  }
}
